import * as React from 'react';
// import '../sass/main.scss';

import FeatherIcon from 'feather-icons-react';
import LayoutDefault from '../layouts/default';
import TextHeader from '../components/textHeader';

// markup
const CareersListingPage = () => {
  return (
    <>
      <LayoutDefault navIsColored={true}>
        <section className="bg-white py-10">
          <div className="container px-5 mt-10">
            <div className="row gx-5">
              <div className="col-lg-3">
                <ul className="list-group list-group-flush list-group-careers">
                  <li className="list-group-item pt-0">
                    <a className="" href="page-careers-overview.html">
                      <FeatherIcon className="me-1 mt-1" icon="arrow-left" />
                      Back to Careers
                    </a>
                  </li>
                  <li className="list-group-item">
                    Remote - Global
                    <i className="fas fa-globe fa-fw text-gray-400" />
                  </li>
                  <li className="list-group-item">
                    Full Time
                    <i className="fas fa-clock fa-fw text-gray-400" />
                  </li>
                  <li className="list-group-item">
                    New Product
                    <i className="fas fa-code fa-fw text-gray-400" />
                  </li>
                </ul>
              </div>
              <div className="col-lg-9">
                <h1 className="mb-4">Software Engineer</h1>
                <p className="lead mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Inventore laboriosam beatae perspiciatis voluptatem quis
                  facere perferendis quae accusantium nisi tempora, eligendi,
                  repellendus cupiditate numquam ullam tempore cum, unde
                  quibusdam ipsum.
                </p>
                <h5 className="mb-4">
                  Here are some types of projects you'll be working on
                </h5>
                <ul className="fa-ul mb-5">
                  <li className="mb-3">
                    <span className="fa-li">
                      <i className="far fa-dot-circle text-primary-soft" />
                    </span>
                    API integrations with new software products
                  </li>
                  <li className="mb-3">
                    <span className="fa-li">
                      <i className="far fa-dot-circle text-primary-soft" />
                    </span>
                    Rapid prototyping of new product ideas
                  </li>
                  <li className="mb-3">
                    <span className="fa-li">
                      <i className="far fa-dot-circle text-primary-soft" />
                    </span>
                    Open source development
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="far fa-dot-circle text-primary-soft" />
                    </span>
                    Expanding and creating new features in existing products
                  </li>
                </ul>
                <h5 className="mb-4">Position description</h5>
                <p className="mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt,
                  saepe quo libero ab perferendis iure impedit inventore
                  quibusdam cumque, aliquid ea tenetur eum velit quidem est
                  tempore! Dignissimos, tenetur saepe.
                </p>
                <p className="mb-4">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt,
                  saepe quo libero ab perferendis iure impedit inventore
                  quibusdam cumque, aliquid ea tenetur eum velit quidem est
                  tempore! Dignissimos, tenetur saepe.
                </p>
                <p className="mb-5">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Harum qui deleniti impedit, sapiente consequuntur accusamus ut
                  quibusdam aliquam repudiandae! Sunt animi obcaecati, nisi
                  cumque placeat earum maiores et minima impedit.
                </p>
                <h5 className="mb-4">Requirements</h5>
                <ul className="fa-ul mb-5">
                  <li className="mb-3">
                    <span className="fa-li">
                      <i className="far fa-dot-circle text-primary-soft" />
                    </span>
                    Familiarity with JS based front-end frameworks (Angular,
                    React, Vue, etc.)
                  </li>
                  <li className="mb-3">
                    <span className="fa-li">
                      <i className="far fa-dot-circle text-primary-soft" />
                    </span>
                    Proficient in programming languages commonly used in web
                    development
                  </li>
                  <li className="mb-3">
                    <span className="fa-li">
                      <i className="far fa-dot-circle text-primary-soft" />
                    </span>
                    Problem solving skills
                  </li>
                  <li className="mb-3">
                    <span className="fa-li">
                      <i className="far fa-dot-circle text-primary-soft" />
                    </span>
                    Ability to prioritize features based on impact
                  </li>
                  <li>
                    <span className="fa-li">
                      <i className="far fa-dot-circle text-primary-soft" />
                    </span>
                    Bachelor's degree in computer science or a related field
                  </li>
                </ul>
                <div className="card bg-light shadow-none">
                  <div className="card-body d-flex align-items-center justify-content-between p-4">
                    <p className="lead mb-0">
                      We look forward to hearing from you
                    </p>
                    <a className="btn btn-primary fw-500 ms-2" href="#!">
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="svg-border-rounded text-black">
            {/* Rounded SVG Border*/}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144.54 17.34"
              preserveAspectRatio="none"
              fill="currentColor"
            >
              <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
            </svg>
          </div>
        </section>
      </LayoutDefault>
    </>
  );
};

export default CareersListingPage;
